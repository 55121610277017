<template>
  <div class="container">
    <b-row class="vh-100" align-v="center">
      <b-col cols="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3">
        <LoginForm ref="loginForm" name="login-form" @login-success="loginSuccess" showForgotPassword="true">
          <template v-slot:logo>
            <img class="inline login-logo" src="@/assets/logo-banner-green.png" />
          </template>
        </LoginForm>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { checkEnv } from '@/lib/enable-feature';

import LoginForm from '@/components/forms/LoginForm';
import LogRocket from '@/lib/logrocket';

import { updateUnleashContext } from '@/lib/unleash';

export default {
  name: 'Login',
  components: { LoginForm },
  metaInfo: {
    title: 'Login to Rafflebox'
  },
  methods: {
    async loginSuccess() {
      const sessionUser = await getAuth().sessionUser();

      if (sessionUser) {
        await updateUnleashContext(sessionUser);
      }

      if (this.$route.query.log) {
        LogRocket.init();

        if (sessionUser) {
          LogRocket.identify(sessionUser.id, sessionUser.email, {
            organizationId: sessionUser.organizationUuid
          });

          this.initialized = true;
        }
      }

      if (sessionUser && checkEnv('Onboarding')) {
        await this.$store.dispatch('setOrganization', sessionUser.organizationUuid);
        await this.$store.dispatch('setEvents');
        await this.$store.dispatch('setOnboarding');

        if (await this.$store.state.displayOnboarding) {
          this.$router.push({ name: 'Onboarding' });
        }
      }

      this.$router.push('/');
    }
  }
};
</script>

<style>
.credentials-form .forgot-password a {
  color: #2c3e50;
}
</style>

<style scoped>
.container {
  margin: auto;
}
</style>
