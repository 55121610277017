<template>
  <div>
    <b-row align-h="start">
      <b-col lg="8" md="10" sm="12">
        <div class="flex">
          <div class="flex-none">
            <font-awesome-icon v-if="getEvents.length" class="fa-4x text-green-500" :icon="['fas', 'circle-check']" />
            <img v-else src="@/assets/svgs/monogram-circle.svg" alt="" class="w-16" />
          </div>
          <div class="ml-4">
            <h2 class="flex items-center font-header font-medium text-2xl mt-4">
              <span class="pr-2"> Create your first raffle </span>
              <Badge v-if="getEvents.length" colorClass="green">Complete</Badge>
            </h2>

            <p class="text pb-4">
              Use this form to fill out the details of your event to the best of your ability. After you hit submit, the
              Rafflebox team will review your raffle and respond to you in five business days or less to finalize your
              raffle details. If you have not yet applied for a charitable gaming licence (where required) the Rafflebox
              team will assist where possible.
            </p>
            <RequestNewRaffleBuildModal :onBoarding="true" v-if="!getEvents.length"
              >Start a
            </RequestNewRaffleBuildModal>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RequestNewRaffleBuildModal from '@/components/modals/RequestNewRaffleBuildModal.vue';
import Badge from '@/components/ui/Badge.vue';

export default {
  components: {
    Badge,
    RequestNewRaffleBuildModal
  },
  computed: {
    getEvents() {
      return this.$store.getters.getEvents;
    }
  }
};
</script>
