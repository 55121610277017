import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faGift,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faGripDotsVertical,
  faPenToSquare,
  faCheck,
  faX,
  faTrashCan,
  faCircleXmark,
  faCirclePlay,
  faCircleStop,
  faInfoCircle,
  faQuestionCircle,
  faCircleRadiation,
  faMedal,
  faEye,
  faMagnifyingGlass,
  faCopy,
  faArrowDownToLine,
  faBan,
  faFloppyDisk,
  faCircleMinus,
  faIdBadge,
  faPlus,
  faArrowUpFromLine,
  faPaperPlaneTop,
  faComments,
  faListTree,
  faDesktop,
  faCircleCheck,
  faCircleExclamation
} from '@awesome.me/kit-9eb3a7bcd0/icons/modules/classic/regular';

import { faToggleOff, faToggleOn } from '@awesome.me/kit-9eb3a7bcd0/icons/modules/duotone/solid';

import {
  faTicket,
  faMoneyBillTransfer,
  faReceipt,
  faCheckCircle,
  faTabletScreenButton,
  faHashtag,
  faGrid,
  faTableList,
  faEnvelope,
  faPrint,
  faDownload
} from '@awesome.me/kit-9eb3a7bcd0/icons/modules/classic/solid';

library.add(
  faGift,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faGripDotsVertical,
  faPenToSquare,
  faCheck,
  faX,
  faTrashCan,
  faCircleXmark,
  faCirclePlay,
  faCircleStop,
  faInfoCircle,
  faQuestionCircle,
  faCircleRadiation,
  faMedal,
  faEye,
  faMagnifyingGlass,
  faCopy,
  faArrowDownToLine,
  faBan,
  faFloppyDisk,
  faCircleMinus,
  faIdBadge,
  faPlus,
  faArrowUpFromLine,
  faPaperPlaneTop,
  faComments,
  faListTree,
  faDesktop,
  faCircleCheck,
  faToggleOff,
  faToggleOn,
  faTicket,
  faMoneyBillTransfer,
  faReceipt,
  faCheckCircle,
  faTabletScreenButton,
  faHashtag,
  faGrid,
  faTableList,
  faEnvelope,
  faPrint,
  faDownload,
  faCircleExclamation
);

export default (vue) => {
  vue.component('FontAwesomeIcon', FontAwesomeIcon);
};
