<template>
  <div>
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-row align-h="start">
      <b-col lg="8" md="10" sm="12">
        <div class="flex">
          <div class="flex-none">
            <font-awesome-icon v-if="accountComplete" class="fa-4x text-green-500" :icon="['fas', 'circle-check']" />
            <img v-else src="@/assets/svgs/monogram-circle.svg" alt="" class="w-16" />
          </div>
          <div class="ml-4">
            <h2 class="flex items-center font-header font-medium text-2xl mt-4">
              <span class="pr-2">Connect your bank account to Stripe Connect</span>
              <Badge v-if="accountComplete" :colorClass="accountStatusVariant">{{ accountStatusMessage }}</Badge>
            </h2>

            <p class="text pb-4">
              You will need to create a new Stripe Connect account for online sales for your raffle. Even if you have a
              Stripe account for another use, you need a separate Stripe Connect account for your raffle as this is
              approved for charitable gaming activities. Have your organization's banking details handy to complete this
              form.
            </p>
            <LoadingButton
              v-if="!this.$store.getters.getOrganization.stripeConnectedAccountId"
              :loading="loading"
              @onClick="createStripeAccount"
              class="group"
              size="medium"
              variant="green-outline"
            >
              <span class="button-name group-hover:text-white">Connect</span>
              <img src="@/assets/svgs/stripe-logo.svg" alt="logo" class="button-image" />
            </LoadingButton>

            <a v-if="stripeRequiresInformation" href="#" class="badge badge-warning mr-1" @click.stop="editInStripe">
              Stripe requires additional information
            </a>
            <a v-if="stripeRequiresBankAccount" href="#" class="badge badge-warning" v-b-modal.edit-stripe-bank-modal>
              Bank account required
            </a>

            <b-row class="mt-2">
              <b-col>
                <b-spinner v-if="editingInStripe" />
                <b-button
                  v-if="stripeRequiresInformation"
                  variant="outline-secondary"
                  @click="editInStripe"
                  class="mb-4"
                  >Edit Details</b-button
                >
                <b-button
                  v-if="stripeRequiresBankAccount"
                  v-b-modal="'edit-stripe-bank-modal'"
                  variant="outline-secondary"
                  class="d-inline ml-2 mb-4"
                >
                  Edit Bank Account
                </b-button>
                <StripeBankAccountModal
                  v-if="stripeAccount"
                  modalId="modal"
                  :stripeAccount="stripeAccount"
                  :organization="this.$store.getters.getOrganization"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StripeBankAccountModal from '@/components/StripeBankAccountModal.vue';
import Badge from '@/components/ui/Badge.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';

import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  components: {
    StripeBankAccountModal,
    Badge,
    LoadingButton
  },
  data() {
    return {
      loading: false,
      editingInStripe: false,
      stripeAccount: null,
      requirements: {},
      success: {
        text: '',
        model: false
      },
      alert: {
        text: '',
        model: false
      }
    };
  },
  async mounted() {
    this.loadStripeAccount();
  },

  computed: {
    stripeRequirements() {
      const requirementList = [];
      if (this.requirements.past_due) requirementList.push(...this.requirements.past_due);
      if (this.requirements.currently_due) requirementList.push(...this.requirements.currently_due);
      if (this.requirements.eventually_due) requirementList.push(...this.requirements.eventually_due);
      return new Set(requirementList);
    },

    stripeRequiresInformation() {
      return (
        this.stripeRequirements.size > 0 &&
        !(this.stripeRequirements.size === 1 && this.stripeRequirements.has('external_account'))
      );
    },

    stripeRequiresBankAccount() {
      return this.stripeRequirements.has('external_account');
    },

    accountComplete() {
      return (
        this.requirements.past_due?.length === 0 &&
        this.requirements.currently_due?.length === 0 &&
        this.requirements.eventually_due?.length === 0
      );
    },

    accountEnabled() {
      return this.requirements.currently_due.length === 0 && this.requirements.eventually_due.length > 0;
    },

    accountRestricted() {
      return this.requirements.currently_due.length > 0;
    },

    accountPending() {
      return this.requirements.pending_verification.length > 0;
    },

    accountRejected() {
      return this.requirements.disabled_reason?.includes('rejected');
    },

    accountStatusVariant() {
      const statusMap = {
        Rejected: 'red',
        Complete: 'green',
        Enabled: 'green',
        Restricted: 'red',
        Pending: 'green',
        Error: 'red'
      };

      return statusMap[this.accountStatusMessage];
    },

    accountStatusMessage() {
      if (this.accountRejected) {
        return 'Rejected';
      }
      if (this.accountComplete) {
        return 'Complete';
      }
      if (this.accountEnabled) {
        return 'Enabled';
      }
      if (this.accountRestricted) {
        return 'Restricted';
      }
      if (this.accountPending) {
        return 'Pending';
      }

      return 'Error';
    }
  },

  methods: {
    async loadStripeAccount() {
      if (this.$store.getters.getOrganization.stripeConnectedAccountId) {
        this.loading = true;
        try {
          const account = await OrganizationServiceV2.retrieveAccount(
            this.$store.getters.getOrganization.stripeConnectedAccountId
          );

          this.stripeAccount = {
            id: account.id,
            name: account.name,
            country: account.country,
            city: account.city,
            address: account.address,
            postal: this.formatPostal(account.postal),
            province: account.province,
            email: account.email,
            active: account.active,
            provider: account.provider,
            firstName: account.firstName,
            lastName: account.lastName,
            phone: this.formatPhone(account.phone),
            availableBalance: this.formatCurrency(account.availableBalanceCents / 100),
            pendingBalance: this.formatCurrency(account.pendingBalanceCents / 100),
            providerData: account.providerData,
            currency: account.currency,
            bankAccount: account.bankAccount
          };

          this.requirements = account.providerData.requirements;
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },

    async editInStripe() {
      this.editingInStripe = true;

      try {
        const response = await OrganizationServiceV2.createAccountLink(
          this.$store.getters.getOrganization.stripeConnectedAccountId,
          this.$store.getters.getOrganization.id
        );

        window.location.href = response.accountLink;
      } catch (error) {
        this.editingInStripe = false;
        this.handleError(error);
      }
    },

    async createStripeAccount() {
      this.loading = true;

      try {
        const response = await OrganizationServiceV2.createAccount('stripe');

        await this.$store.dispatch('setStripeConnectedAccountId', response.accountId);
        this.editInStripe();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
    },

    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    }
  }
};
</script>
<style scoped>
.button-image {
  width: 4rem;
  align-self: center;
  margin-bottom: -3px;
}
.button-name {
  line-height: 1;
  padding-right: 0.25rem;
  color: #2c3e50;
}
</style>
